<template>
  <dialog-outline :scrollable="true" v-model="showDialog">
    <template v-slot:icon-name>
      {{ dialogIcon }}
    </template>
    <template v-slot:default>
      {{ dialogTitle }}
    </template>

    <!-- Close Dialog icon -->
    <template v-slot:icon-cross>
      <base-tooltip-button @click="closeDialog">
        <template v-slot:icon-name>
          {{ iconCloseDialog }}
        </template>
        <template v-slot:toolTip>
          {{ closeDialogTooltip }}
        </template>
      </base-tooltip-button>
    </template>

    <!-- context -->
    <template v-slot:pre-context>
      <!-- display error -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </template>

    <!-- context -->
    <template v-slot:context>
      <v-card flat :loading="loading">
        <v-row dense>
          <v-col>
            <v-treeview
              :active.sync="activeNodes"
              :open.sync="openNodes"
              :items="nodes"
              :load-children="fetchChildren"
              item-key="key"
              activatable
              open-on-click
              transition
              @update:active="onTreeSelectedItemChange"
              @update:open="onTreeSelectedItemOpen"
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-if="visibleTreeNodeIcon(item)">
                  {{ treeNodeIcon(item) }}
                </v-icon>
              </template>
            </v-treeview>
          </v-col>
        </v-row>
      </v-card>
    </template>

    <!--  Dialog Actions -->
    <template v-slot:actions>
      <base-button :disabled="!enableOkCommand" @click="onOk">{{
        dialogOkCommandLabel
      }}</base-button>
    </template>

    <!-- Cancel Action -->
    <template v-slot:actionCancel>
      <base-button @click.stop="closeDialog()">
        <template v-slot:default>
          {{ dialogCancelCommandLabel }}
        </template>
      </base-button>
    </template>
  </dialog-outline>
</template>

<script>
// model
import {
  findProjectTemplateOperation,
  projectTemplateEntity,
  projectTemplateLabel,
  projectTemplateOperation
} from "@/model/workflow/project/projectTemplateModel";
import { actionResultType } from "@/model/action/actionModel";

// utils
import { isEmpty } from "@/utils";

// design
import { iconProjects } from "@/design/icon/iconConst";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("project");

// mixins
import { dialogOutlineMixin } from "@/mixins/shared/base/dialog/dialogOutlineMixin";

export default {
  name: "ProjectTemplateBrowser",
  mixins: [dialogOutlineMixin],
  props: {
    /**
     * Function to perform
     */
    performAction: {
      type: Function,
      required: true
    },

    /**
     * workflow project Template to be selected
     * @type {{id: number, name: string, assignAutoToOwner: boolean, calendar: string, canReassign: boolean, comment: string, created: string, definitionId: number, description: string, isDisabled: boolean, isHidden: boolean, notifyOnAborted: boolean, notifyOnAssignment: boolean, notifyOnAvailable: boolean, notifyOnCanceled: boolean, notifyOnCompletedFailure: boolean, notifyOnCompletedSuccess: boolean, projectManager: string, role: string, workflowName: string, workflowTypeId: number, userTaskTemplates: {id: number, name: string, sequence: number, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}, duration: {calendar: string, duration: string, fixed: boolean, milestone: boolean}}}
     */
    selectTemplate: undefined
  },
  data() {
    return {
      /**
       * progress flag
       * @type boolean
       */
      loading: false,

      /**
       * array of node item-key to be active (selected)
       * @type {{string}[]}
       */
      activeNodes: [],

      /**
       * array of node item-key to be expanded
       * @type {{string}[]}
       */
      openNodes: [],

      /**
       * tree-view nodes
       * @type {{name: string, id, type: string, key: string, children: *[]}[]}
       */
      nodes: [],

      /**
       * project template icon
       * @type {string}
       */
      iconProjects: iconProjects,

      /**
       * array of selected tree view items
       * @type {{string}[]}
       */
      selectedNodes: undefined
    };
  },
  computed: {
    ...mapState({
      projectCategories: "projectCategories"
    }),

    /**
     * Tree-view
     */
    selectedProjectTemplateId() {
      if ((this.activeNodes?.length ?? 0) === 0) return undefined;

      const arr = this.activeNodes[0].split("_") ?? [];
      if ((arr?.length ?? 0) > 1) {
        return Number(arr[1]);
      }

      return undefined;
    },

    /**
     * download Operation
     * @return {{name: string, icon: string, label: string}} download Operation
     */
    operation() {
      return findProjectTemplateOperation(projectTemplateOperation.select);
    },

    /**
     * operation Label
     * @return {string}
     */
    operationLabel() {
      return this.operation?.label;
    },

    /**
     * dialog Icon
     * @return {string} Icon name
     */
    dialogIcon() {
      return this.operation?.icon;
    },

    /**
     * Download Dialog Title
     * @return {string} Formatted download Dialog Title
     */
    dialogTitle() {
      return `${this.operationLabel} ${projectTemplateLabel}`;
    },

    /**
     * Dialog action
     * @return {string}
     */
    action() {
      return this.dialogTitle;
    },

    /**
     * dialog Ok Command Label
     * remarks: overwrite modalDialogMixin.dialogOkCommandLabel
     * @return {string} OK command Label
     */
    dialogOkCommandLabel() {
      return this.operationLabel;
    },

    /**
     * computed whether to enable Ok Command
     * @return {boolean}
     */
    enableOkCommand() {
      return this.selectedProjectTemplateId > 0;
    }
  },
  methods: {
    ...mapActions({
      setWorkflowProjectTemplates: "setWorkflowProjectTemplates",
      setWorkflowProjectTemplate: "setWorkflowProjectTemplate",
      setWorkflowProjectCategories: "setWorkflowProjectCategories",
      setProjectCategoryTemplates: "setProjectCategoryTemplates"
    }),

    /**
     * load workflow project Templates
     * @return {Promise<void>}
     */
    async loadTemplates() {
      try {
        this.clearAlert();
        this.loading = true;

        console.log(
          `${this.$options.name}.loadTemplates() this.selectTemplate:`,
          this.selectTemplate
        );

        // load Workflow Project Categories
        if ((this.projectCategories?.length ?? 0) === 0) {
          await this.setWorkflowProjectCategories();
        }

        // if requested select category, fetch Workflow Project Category Templates
        const categoryId = this.selectTemplate?.projectCategory?.id ?? -1;
        // let templates =
        //   categoryId > 0
        //     ? (await this.fetchProjectCategoryTemplates(categoryId)) ?? []
        //     : [];
        let templates = [];
        if (categoryId > 0) {
          const pc = this.projectCategories?.find(pc => pc.id === categoryId);
          if (pc) {
            if ((pc?.templates?.length ?? 0) === 0) {
              templates =
                (await this.setProjectCategoryTemplates(categoryId)) ?? [];
            } else {
              templates = pc?.templates;
            }
          }
        }

        // display tree-view nodes
        let activeTemplate = undefined;
        this.nodes = this.projectCategories?.map(c => {
          // REMARKS: populate children if requested selected category
          let children = [];
          if (categoryId === c.id) {
            children = templates?.map(t => {
              return this.createTreeNode(t, projectTemplateEntity.template);
            });
            // REMARKS: define node to be selected (active node)
            activeTemplate = children?.find(
              c => c.id === this.selectTemplate?.id ?? -1
            );
          }
          return this.createTreeNode(
            c,
            projectTemplateEntity.category,
            children
          );
        });

        //
        // REMARKS: this.openNodes/this.activeNodes only can be set if nodes are loaded to tree-view
        //

        // set open Nodes (expand nodes)
        this.openNodes =
          categoryId > 0
            ? [
                this.createTreeNodeKey(
                  categoryId,
                  projectTemplateEntity.category
                )
              ]
            : [];

        // set active Nodes (selected nodes)
        this.activeNodes =
          activeTemplate?.id > 0
            ? [
                this.createTreeNodeKey(
                  activeTemplate.id,
                  projectTemplateEntity.template
                )
              ]
            : [];
      } catch (e) {
        console.error(e);
        this.internalAlert = this.createAlertError(
          this.formatAlertError(`Load ${projectTemplateLabel}s`, e)
        );
      } finally {
        this.loading = false;
      }
    },

    /**
     * handle OK/accept button click event
     * @return {Promise<void>}
     */
    async onOk() {
      try {
        this.clearAlert();

        const result = await this.performAction(this.selectedProjectTemplateId);

        if (result?.type === actionResultType.success) {
          await this.autoCloseDialog(result.message);
        } else {
          if (!isEmpty(result.message)) {
            this.internalAlert = this.createAlertWarning(result.message);
          }
        }
      } catch (e) {
        this.internalAlert = this.createAlertError(
          this.formatAlertError(this.action, e)
        );
      }
    },

    /**
     * fetch Children
     * @param {{id:number, name:string, children: {name: string, id, type: string, key: string, children: *[]}}} item expanded tree-view item
     */
    async fetchChildren(item) {
      try {
        const categoryId = item?.id ?? -1;

        let templates = [];
        const pc = this.projectCategories?.find(pc => pc.id === categoryId);
        if (pc) {
          if ((pc?.templates?.length ?? 0) === 0) {
            templates =
              (await this.setProjectCategoryTemplates(categoryId)) ?? [];
          } else {
            templates = pc?.templates;
          }
        }

        item.children = templates?.map(t => {
          return this.createTreeNode(t, projectTemplateEntity.template);
        });
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * determine visibility of tree-view node icon
     * @param {{id: number, name: string, type: number}}  node tree-view node
     * @return {boolean} true if tree-view node icon is visible
     */
    visibleTreeNodeIcon(node) {
      return node?.type === projectTemplateEntity.template;
    },

    /**
     * get tree-view note icon
     * @param {{id: number, name: string, type: number}} node tree-view node
     * @return {string|undefined} tree-view item icon name
     */
    treeNodeIcon(node) {
      return node?.type === projectTemplateEntity.template
        ? iconProjects
        : undefined;
    },

    /**
     * create tree-view node
     * @param {{id: number, name: string}} item item
     * @param {number} type item type
     * @param children indicate whether item can have children
     * @return {{name: string, id, type: string, key: string, children: *[]}}
     */
    createTreeNode(item, type, children = undefined) {
      return {
        id: item.id,
        name: item.name,
        key: this.createTreeNodeKey(item.id, type),
        type: type,
        children: children
      };
    },

    /**
     *  create Tree-view Node Key
     * @param { number } id Item id
     * @param {number} type item type
     * @return {string} Tree-view Item Key
     */
    createTreeNodeKey(id, type) {
      return `${type}_${id}`;
    },

    /**
     * handle event v-tree-view @update:active
     * which Emits the array of active items when this value changes
     * @param {{string}[]} items the array of selected items
     */
    onTreeSelectedItemChange(items) {
      this.selectedNodes = items;
      console.log(
        `${this.$options.name}.onTreeSelectedItemChange() this.selectedNodes:`,
        this.selectedNodes
      );

      console.log(
        `${this.$options.name}.onTreeSelectedItemChange() this.selectedProjectTemplateId:`,
        this.selectedProjectTemplateId
      );

      const t = this.findProjectTemplate(this.selectedProjectTemplateId);
      console.log(`onTreeSelectedItemChange() project template:`, t);
      console.log(
        `onTreeSelectedItemChange() this.projectCategories:`,
        this.projectCategories
      );
    },

    /**
     * find Project Template
     * @param {number} templateId Project Template id
     * @return {undefined}
     */
    findProjectTemplate(templateId) {
      let template = undefined;
      this.projectCategories?.forEach(pc => {
        const t = pc.templates?.find(t => t.id === templateId);
        if (t) {
          template = t;
          return template;
        }
      });
      return template;
    },

    /**
     * handle event v-tree-view @update:open
     * which Emits the array of open items when this value changes
     * @param items the array of open items
     */
    onTreeSelectedItemOpen(items) {
      console.log(
        `${this.$options.name}.onTreeSelectedItemOpen() items:`,
        items
      );
    }
  },
  watch: {
    /**
     * watch showDialog
     * @param {boolean} newValue
     * @return {Promise<void>}
     */
    async showDialog(newValue) {
      if (newValue) {
        await this.loadTemplates();
      } else {
        // code cleanup here ..
        this.nodes = [];
        this.openNodes = [];
        this.activeNodes = [];
      }
    },

    /**
     * watch activeNodes
     * @param newValue
     */
    activeNodes(newValue) {
      console.log(`watch ${this.$options.name} this.activeNodes:`, newValue);
    },

    /**
     * watch openNodes
     * @param newValue
     */
    openNodes(newValue) {
      console.log(`watch ${this.$options.name} this.openNodes:`, newValue);
    }
  }
};
</script>
